// HotelRoomsSection.tsx
import React from "react";
import { Col, Row } from "react-bootstrap";
import { ISearchData } from "../../../../components/common/Search/SearchAutoComplete/SearchAutoCompleteProps";
import IHotel from "../../../../@types/IHotel";
import { AgentLink, StyledContainer } from "../../HotelPageHelpers";
import { Subtitle } from "../../../../pageHelpers/Contact/ContactHelperComponents";
import Title2 from "../../../../components/global/Title2/Title2";
import HotelRooms from "../../../../components/common/HotelRooms/HotelRooms";

interface HotelRoomsSectionProps {
  searchData: ISearchData;
  hotel: IHotel;
  isWebFramed: boolean;
  handleOnRoomsLoad: (
    offerAvailable: any,
    rate: any,
    discountType?: string
  ) => void;
  unlockBannerShow: boolean;
  hotelInfoUrgBgColor: string;
  hotelInfoUrgFontColor: string;
}

const HotelRoomsSection: React.FC<HotelRoomsSectionProps> = ({
  searchData,
  hotel,
  isWebFramed,
  handleOnRoomsLoad,
  unlockBannerShow,
  hotelInfoUrgBgColor,
  hotelInfoUrgFontColor,
}) => (
  <StyledContainer
    fluid
    className="bg-light accommodations-container position-relative"
  >
    <>
      <a
        className="anchor-target visually-hidden"
        id="rooms"
        aria-hidden={true}
      />
      <StyledContainer fluid="sm">
        <Row>
          <Col className="text-center text-lg-left hotelRooms">
            <Subtitle>Accommodations</Subtitle>
            <Title2 className="text-left">
              {searchData.rooms && searchData.rooms.length > 1
                ? "Select Your Rooms"
                : "Select Your Room"}
            </Title2>
            {!isWebFramed && (
              <AgentLink
                search={searchData}
                hotel_code={hotel.crs_code}
                chain_id={hotel.crs_chain_id}
              />
            )}
            <HotelRooms
              hotel={hotel}
              onRoomsLoad={handleOnRoomsLoad}
              unlockBannerShow={unlockBannerShow}
              hotelInfoUrgBgColor={hotelInfoUrgBgColor}
              hotelInfoUrgFontColor={hotelInfoUrgFontColor}
            />
          </Col>
        </Row>
      </StyledContainer>
    </>
  </StyledContainer>
);

export default HotelRoomsSection;
